export const GET_ORDER = "GET_ORDER";
export const ORDER_FINISH = "ORDER_FINISH";

const initialState = {
  orderTime: "",
  isOrderTaken: false,
};

const OrderReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_ORDER:
      return { ...state, orderTime: action.payload, isOrderTaken: true };
    case ORDER_FINISH:
      return { ...state, orderTime: "", isOrderTaken: false };

    default:
      return state;
  }
};

export default OrderReducer;
