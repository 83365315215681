import React from 'react';
import { useSelector } from 'react-redux';
import closeIcon from '../Assets/close-modal.png';

const CheckAge = ({checkingAge, setCheckingAge, setIsAdult}) => {
    const language = useSelector((state) => state.translation.language);

    const onCheckAge = (value) => setIsAdult(value);
    const handleClose = () => setCheckingAge(null);

    return (
        <div
        style={{position: 'fixed', top: 0, right: 0, bottom: 0, left: 0, background: 'rgba(0, 0, 0, 0.5)',
        display: checkingAge ? 'flex' : 'none', alignItems: 'center', justifyContent: 'center', zIndex: 10,}}
        >
            <div
                style={{background: 'rgb(6, 24, 28)', width: 500, height: 200, display: 'flex',
                alignItems: 'center', justifyContent: 'center', flexDirection: 'column',
                position: 'relative', borderRadius: 10}}
            >
                <img 
                    onClick={handleClose}
                    src={closeIcon} 
                    alt={"name"} 
                    style={{position: 'absolute', top: 15, right: 15}} 
                />
                <div style={{fontSize: 25, marginBottom: 20}}>
                    {language === "ENG" && "Are you 18 years old or older?"}
                    {language === "Pyc" && "Вам 18 лет или больше?"}
                    {language === "EST" && "Kas olete 18-aastane või vanem?"}
                    {language === "LV" && "Vai jums ir 18 gadi vai vairāk?"}
                </div>
                
                <div>
                    <button
                        onClick={() => onCheckAge('no')}
                        style={{background: 'rgb(10, 41, 48)', border: 'none',
                        color: '#fff', height: 40, width: 100, marginRight: 20,
                        borderRadius: 20 }}
                    >
                        {language === "ENG" && "No"}
                        {language === "Pyc" && "Нет"}
                        {language === "EST" && "Ei"}
                        {language === "LV" && "Nē"}
                    </button>
                    <button
                        onClick={() => onCheckAge('yes')}
                        style={{background: 'rgb(10, 41, 48)', border: 'none',
                        color: '#fff', height: 40, width: 100, borderRadius: 20}}
                    >
                        {language === "ENG" && "Yes"}
                        {language === "Pyc" && "Да"}
                        {language === "EST" && "Jah"}
                        {language === "LV" && "Jā"}
                    </button>
                </div>
            </div>
        </div>
    )
}

export default CheckAge;