export const CHANGE_TABLE_NUMBER = "CHANGE_TABLE_NUMBER";

const initialState = {
  tableNumber: "",
};

const userSettingsReducer = (state = initialState, action) => {
  switch (action.type) {
    case CHANGE_TABLE_NUMBER:
      return { tableNumber: action.payload };

    default:
      return state;
  }
};

export default userSettingsReducer;
