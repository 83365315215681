import React, { useEffect, useState } from "react";
import OtpInput from "react-otp-input";
import { withRouter, useLocation } from "react-router";
import logo from "../Assets/logo.png";
import { useDispatch, useSelector } from "react-redux";
import { instance } from "../Api/init";

const Introduction = (props) => {
  const [language, setLanguage] = useState();
  const dispatch = useDispatch();
  const location = useLocation();
  // const tableNumber = useSelector((state) => state.userSettingsKey);
  const selectedLanguage = useSelector((state) => state.translation.language);
  const currentRestaurant = useSelector((state) => state.translation.currentRestaurant);

  const changeLanguage = (lang) => {
    dispatch({ type: "CHANGE_LANGUAGE", payload: lang });
    setLanguage(lang);
  };
  const restaurantName = localStorage.getItem('restaurantName');

  const [input, setInput] = useState("1");

  const navigateToMenu = () => {
    if (input.length >= 1) {
      localStorage.setItem('table_number', input)
      props.history.push(`menu`);
      dispatch({ type: "CHANGE_TABLE_NUMBER", payload: input });
    }
  };
  const getRestaurants = () => {
    instance.get(`get-restaurants`)
      .then(resp => {
        const restaurant = resp.data.response.restaurants.filter(restaurant => restaurant.id == restaurantName)
        dispatch({ type: "GET_CURRENT_RESTAURANT", payload: restaurant[0] });
      })
  };

  useEffect(() => {
    getRestaurants();
  }, [])

  useEffect(() => {
    if (currentRestaurant) {
      currentRestaurant.id === 30 ? setLanguage('LV') : setLanguage('EST');
      dispatch({ type: "CHANGE_LANGUAGE", payload: language });
    }
  }, [currentRestaurant])

  useEffect(() => {
    dispatch({ type: "CHANGE_LANGUAGE", payload: language });
  }, [language])

  return (
    <div style={{ backgroundColor: "#06181C", height: "100%" }}>
      <div class="container-fluid" style={{ height: "100%" }}>
        <div class="row justify-content-center">
          <div
            class="col-12 justify-content-center"
            style={{ marginTop: "1%" }}
          >
            {
              currentRestaurant && currentRestaurant.id !== 30 &&
              <span
                onClick={() => changeLanguage("EST")}
                style={{
                  fontWeight: language === "EST" ? "700" : "400",
                  color:
                    language === "EST" ? "white" : "rgba(255, 255, 255, 0.7)",
                  margin: 5,
                  cursor: "pointer",
                  backgroundColor:
                    language === "EST"
                      ? "rgba(255, 255, 255, 0.15)"
                      : "transparent",
                  borderRadius: 50,
                  padding: 10,
                }}
              >
                EST
              </span>
            }
            <span
              onClick={() => changeLanguage("Pyc")}
              style={{
                fontWeight: language === "Pyc" ? "700" : "400",
                color:
                  language === "Pyc" ? "white" : "rgba(255, 255, 255, 0.7)",
                margin: 5,
                cursor: "pointer",
                backgroundColor:
                  language === "Pyc"
                    ? "rgba(255, 255, 255, 0.15)"
                    : "transparent",
                borderRadius: 50,
                padding: 10,
              }}
            >
              RUS
            </span>
            <span
              onClick={() => changeLanguage("ENG")}
              style={{
                fontWeight: language === "ENG" ? "700" : "400",
                color:
                  language === "ENG" ? "white" : "rgba(255, 255, 255, 0.7)",
                margin: 5,
                cursor: "pointer",
                backgroundColor:
                  language === "ENG"
                    ? "rgba(255, 255, 255, 0.15)"
                    : "transparent",
                borderRadius: 50,
                padding: 10,
              }}
            >
              ENG
            </span>
            {
              currentRestaurant && currentRestaurant.id === 30 &&
              <span
                onClick={() => changeLanguage("LV")}
                style={{
                  fontWeight: language === "LV" ? "700" : "400",
                  color:
                    language === "LV" ? "white" : "rgba(255, 255, 255, 0.7)",
                  margin: 5,
                  cursor: "pointer",
                  backgroundColor:
                    language === "LV"
                      ? "rgba(255, 255, 255, 0.15)"
                      : "transparent",
                  borderRadius: 50,
                  padding: 10,
                }}
              >
                LV
              </span>
            }
          </div>

          <div class="row" style={{ marginTop: "4%", marginBottom: "4%" }}>
            <div class="col-12 justify-content-center ">
              <img src={logo} style={{ width: 121, height: 57 }} alt={"logo"} />
            </div>
          </div>

          <div class="row">
            <div
              class="col-12 text-center"
              style={{ color: "white", fontSize: 55, fontWeight: "700" }}
            >
              {language === "ENG" && "Welcome"}
              {language === "Pyc" && "Приветствуем!"}
              {language === "EST" && "Tere!"}
              {language === "LV" && 'Sveiki!'}

            </div>
          </div>
          <div class="row">
            <div
              class="col-12 text-center"
              style={{
                color: "rgba(255, 255, 255, 0.7)",
                fontSize: 20,
                fontWeight: "400",
              }}
            >
              {language === "ENG" && "Selecr your table number"}
              {language === "Pyc" && "Оберите номер столика за которым вы сидите!"}
              {language === "EST" && "Valige oma tabeli number"}
              {language === "LV" && 'Izvēlieties tabulas numuru'}
            </div>
          </div>

          <div
            class="row justify-content-center"
            style={{ marginTop: "5%", marginBottom: "5%" }}
          >
            <div class="col-12 justify-content-center text-center">
              <select
                className="select-table"
                onChange={e => setInput(e.target.value)}
              >
                <option selected>1</option>
                <option>2</option>
                <option>3</option>
                <option>4</option>
                <option>5</option>
                <option>6</option>
                <option>7</option>
                <option>8</option>
                <option>9</option>
                <option>10</option>
              </select>
              {/* <OtpInput
                value={input}
                onChange={(e) => setInput(e)}
                numInputs={3}
                inputStyle={{
                  textAlign: "center",
                  outline: "none",
                  border: "none",
                  borderBottom: "1px solid white",
                  color: "white",
                  backgroundColor: "transparent",
                  width: 62,
                  margin: 5,
                  fontSize: 55,
                  fontWeight: "700",
                  height: 100,
                }}
                shouldAutoFocus={true}
                isInputNum={false}
                containerStyle={{ justifyContent: "center" }}
              /> */}
            </div>
          </div>

          <div class="row" style={{ marginTop: "4%", marginBottom: "5%" }}>
            <div class="col-12 justify-content-center">
              <button
                style={{
                  border: "none",
                  padding: 15,
                  backgroundColor: "#ECAB04",
                  width: 171,
                  color: "white",
                  borderRadius: 125,
                  fontSize: 13,
                  fontWeight: "700",
                }}
                onClick={navigateToMenu}
              >
                {language === "ENG" && "Next"}
                {language === "Pyc" && "Далее"}
                {language === "EST" && "Edasi"}
                {language === 'LV' && 'Nākamais'}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default withRouter(Introduction);
