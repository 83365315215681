import axios from 'axios';


// export const instance = axios.create({baseURL: "https://mysushi.ee/public/api/"});
export const admitInstance = axios.create({ baseURL: "https://admin.menumysushi.ee/api/" });
export const instance = axios.create({ baseURL: "https://mere.mysushi.ee/api/" });

export const getConfig = () => {
  const headers = {
    'Content-Type': 'application/json',
    Accept: 'application/json',
  };

  return { headers };
};