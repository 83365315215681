export const GET_MENU = "GET_MENU";
export const GET_MENU_NAME = "GET_MENU_NAME";
export const GET_MENU_ID = "GET_MENU_ID";
export const GET_DISHES = "GET_DISHES";
export const GET_ADDITIONALS = "GET_ADDITIONALS";
export const GET_CATEGORIES = "GET_CATEGORIES";
export const GET_DISCOUNTS = "GET_DISCOUNTS";
export const GET_DISCOUNTS_DISHES = "GET_DISCOUNTS_DISHES";
export const GET_DISHES_OF_THE_DAY = "GET_DISHES_OF_THE_DAY";
export const GET_DISHES_OF_THE_DAY_DISHES = "GET_DISHES_OF_THE_DAY_DISHES";

const initialState = {
  menu: [],
  menuId: null,
  menuName: [],
  dishes: [],
  additionals: [],
  categories: [],
  discounts: {},
  dishesOdTheDay: {}
};

const DishesMenuReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_MENU:
      return { ...state, menu: action.payload };
    case GET_MENU_ID:
      return { ...state, menuId: action.payload };
    case GET_MENU_NAME:
      return { ...state, menuName: action.payload };
    case GET_CATEGORIES:
      return { ...state, categories: action.payload };
    case GET_DISHES:
      return { ...state, dishes: action.payload };
    case GET_ADDITIONALS:
      return { ...state, additionals: action.payload }
    case GET_DISCOUNTS:
      return { ...state, discounts: action.payload }
    case GET_DISCOUNTS_DISHES:
      return { ...state, dishes: [...action.payload] };
    case GET_DISHES_OF_THE_DAY:
      return { ...state, dishesOdTheDay: action.payload }
    case GET_DISHES_OF_THE_DAY_DISHES:
      return { ...state, dishes: [...action.payload] }
    default:
      return state;
  }
};

export default DishesMenuReducer;
