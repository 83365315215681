import React, { useState } from "react";
import Modal from "react-modal";

import LoaderModal from "./LoaderModal";
import Dashboard from "./Dashboard";
import SideBar from "./SideBar";
const HomeMenu = () => {

  const [isLoad, setIsLoad] = useState(false);

  const customStylesLoader = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "10px",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      backgroundColor: "#06181C",
      width: "100vw",
      height: "100vh",
      overflow: "hidden",
      border: "none",
    },
  };

  return (
    <div style={{ backgroundColor: "#06181c", height: "100%", marginTop: 30 }}>
      <div class="container-fluid">
        <div class="row">
          <div
            class="col-4"
            style={{
              color: "white",
              height: "100%",
              background: "linear-gradient(180deg, #07171A 0%, #04262C 69.79%), #02151A;",
              width: '320px'
            }}
          >
            <SideBar isLoad={isLoad} setIsLoad={setIsLoad}/>
          </div>
          <div
            class="col-8"
            style={{ color: "white", background: "linear-gradient(180deg, #023D48 0%, #04262C 18.23%)", width: 'calc(100% - 320px)' }}
          >
            <Dashboard isLoad={isLoad} setIsLoad={setIsLoad}/>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomeMenu;
