import React, { useEffect } from "react";
import tick from "../Assets/tick.png";
import cross from "../Assets/Cross.png";
import { useSelector } from "react-redux";
import axios from "axios";
import { admitInstance } from "../Api/init";

const Waiter = ({ closeModal }) => {
  const language = useSelector((state) => state.translation.language);
  const restaurantName = localStorage.getItem('restaurantName');
  const table_number = localStorage.getItem('table_number');
  const order_id = localStorage.getItem('order_id')

  useEffect(() => {
    admitInstance.post('garcon/call', {
      restaurant_id: restaurantName,
      table_number,
      status: '1',
      order_id: order_id ? order_id : null
    })
    setTimeout(() => {
      closeModal()
    }, 5000);

  }, [])

  return (
    <div
      style={{
        backgroundColor: "#ECAB04",
        justifyContent: "space-between",
        padding: 5,
        height: "100%",
        alignItems: "center"

      }}
      class="d-flex"
    >
      <div
        style={{ color: "white", justifyContent: "space-between" }}
        class="d-flex"
      >
        <div>
          {" "}
          <img
            src={tick}
            style={{
              width: 18,
              height: 18,
              marginRight: 20,
              marginTop: -3,
            }}
            alt={"tick"}
          />
        </div>
        <div style={{ marginLeft: 10, fontWeight: 600 }}>
          {language === "ENG" && "Waiter has got notification and will come to you soon"}
          {language === "Pyc" && "Официант получил уведомление и скоро подойдёт к вашему столику"}
          {language === "EST" && "Teenindaja sai teavituse ja varsti tuleb teie lauda"}
          {language === "LV" && "Viesmīlis ir informēts un drīzumā jūs apkalpos"}

        </div>
      </div>
      <div style={{ color: "white", marginRight: 10 }} onClick={closeModal}>
        <img
          src={cross}
          style={{ width: 12, height: 12, cursor: "pointer" }}
          alt="cross"
        />
      </div>
    </div>
  );
};

export default Waiter;
