import React from "react";
import { withRouter } from "react-router";
import Loader from "react-loader-spinner";

const LoaderModal = () => {
  return (
    <div
      class="container-fluid"
      style={{ justifyContent: "center", alignItems: "center" }}
    >
      <div
        class="row d-flex text-center"
        style={{
          marginTop: 20,
          justifyContent: "center",
          color: "white",
          fontSize: 28,
          fontWeight: "700",
          alignItems:'center'
        }}
      >
        <div style={{marginTop:'30vh'}}>
            <Loader
              type="TailSpin"
              color="#ECAB04"
              height={100}
              width={100}
              timeout={3000} //3 secs
            />
            </div>
      </div>
    </div>
  );
};

export default withRouter(LoaderModal);
