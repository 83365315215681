import reducer from './rootReducer';

import {createStore} from 'redux';
// import {persistStore} from 'redux-persist';
// import {persistReducer} from 'redux-persist';

// const persistConfig = {
//   key: 'root',
//   storage: AsyncStorage,
// };

// const persisedReducer = persistReducer(persistConfig, reducer);
export const store = createStore(reducer);

// export const persistor = persistStore(store);
