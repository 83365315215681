import React, { useState } from "react";
import Modal from "react-modal";
import "../App.css";
import HomeMenu from "./HomeMenu";
import Waiter from "./Waiter";
import check from "../Assets/check.png";
import waiter from "../Assets/waiter.png";
import RequestModal from "./RequestModal";

import Payment from "./Payment";
import RightMenu from "./RightMenu";
// import Pending from "./Pending";
// import Clock from "./Clock";
import Loader from "react-loader-spinner";

import { useSelector, useDispatch } from "react-redux";
import { withRouter } from "react-router";
import Languages from "./Languages";
import { useHistory } from "react-router";

const customStyles = {
  content: {
    top: "80%",
    height: 80,
    backgroundColor: "#ECAB04",
    borderRadius: 10,
    border: "none",
    overflow: "hidden",
  },
};

const customStylesRequest = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "10px",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    backgroundColor: "#06181C",
    width: "60vw",
    height: "60vh",
    border:'none',
    boxShadow: '0px 0px 50px rgba(0, 0, 0, 0.2)',
    borderRadius: 5,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
};
const customStylesPayment = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "10px",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    backgroundColor: "#06181C",
    width: "708px",
    borderRadius: '5px',
    boxShadow: '0px 0px 50px rgba(0, 0, 0, 0.2)',
    maxHeight: "95vh",
    overflow: "hidden",
    zIndex: 10,
    border: 'none',
    padding: "51px 61px 33px",
    position: 'relative'
    // border:'none',
  },
};

const customStylesRightMenu = {
  content: {
    top: -5,
    marginLeft: "45%",
    backgroundColor: "#06181C",
    width: "52vw",
    height: "110%",
    border: "none",
  },
};

const MainMenu = (props) => {
  // const dispatch = useDispatch();
  const tableNumber = useSelector((state) => state.userSettingsKey);
  const language = useSelector((state) => state.translation.language);
  const history = useHistory();
  const [callWaiter, setCallWaiter] = useState(false);
  const [getInvoice, setGetInvoice] = useState(false);
  const [paymentModal, setPaymentModal] = useState(false);
  const [paymentNow, setPaymentNow] = useState(false);
  const dispatch = useDispatch();


  const [
    pendingOrder,
    // setPendingOrder
  ] = useState(false);
  // const [clockOrder, setClockOrder] = useState(false);

  const [rightMenuModal, setRightMenuModal] = useState(false);
  const dishesMenuData = useSelector((state) => state.dishesMenuReducer);

  const cartItems = useSelector((state) => state.cartReducer);
  const table_number = localStorage.getItem('table_number');


  const cartValue = cartItems.Carts.reduce(
    (sum, i) => (sum += i.quantity * i.price),
    0
  );

  const callWaiterModal = () => {
    setCallWaiter(true);
  };

  function closeModal() {
    setCallWaiter(false);
  }

  const getInvoiceModal = () => {
    setGetInvoice(true);
    // props.history.push("/thanks");
    setPaymentModal(false);
  };

  function closeModalInvoice() {
    setGetInvoice(false);
  }
  const showPaymentModal = () => {
    setPaymentModal(true);

  };

  function closePaymentModal() {
    setPaymentModal(false);
    setPaymentNow(false)
  }

  function showRightMenu() {
    if (cartItems.Carts.length >= 1) {
      setRightMenuModal(true);
    }
  }
  function closeRightMenu() {
    setRightMenuModal(false);
  }

  function backHandle() {
    history.push('/');
    dispatch({ type: "CLEAN_CARDS", payload: null});
  }

  return (
    <>
      <div style={{ backgroundColor: "#041418" }}>
        {dishesMenuData.menu.length >= 1 ? (
          <div
        
            class={
              paymentModal ? "container-fluid" : "container-fluid fixed-top"
            }
            style={{ backgroundColor: "#041418", height: 62, zIndex: 2 }}
          >
            <div class="row" style={{alignItems: 'center'}}>
              {/* {!pendingOrder && <div class="col-1" />} */}
              <div
                class={"col-3"}
              >
                <div class="row" style={{ color: "grey" }}>
                  <div class="col-2"></div>
                  <div class="col-2" onClick={backHandle}>
                    <div class="row" style={{fontWeight: 300, fontSize: '15px', lineHeight: '16px',
                        color: 'rgba(255, 255, 255, 0.8)', marginBottom: 5, width: 'max-content'}}>
                        {language === "ENG" && "Table number"}
                        {language === "Pyc" && "Стол"}
                        {language === "EST" && "Lauanumber"}
                        {language === "LV" &&  "Galdiņa numurs"}
                    </div>
                    <div class="row" style={{ color: "white",  width: 'max-content', fontWeight: 'bold', fontSize: 16, lineHeight: '20px' }}>
                      № {table_number}
                    </div>
                  </div>
                  <div class="col-8">{/* {pendingOrder && <Pending />} */}</div>
                </div>
                {/* <div class="row" style={{ color: "white" }}> */}
                {/* </div> */}
              </div>
              <div
                class="col-5"
              >
                <div class="row">
                  <div class="col-12" style={{ color: "#ECAB04" }}>
                    <div class="row">
                      <div class="col-2"></div>
                      <div
                        class="col-10 justify-content-center"
                        style={{ cursor: "pointer" }}
                        onClick={callWaiterModal}
                      >
                        <span class="justify-content-center">
                          <img
                            src={waiter}
                            style={{
                              width: 18,
                              height: 18,
                              marginRight: 20,
                              marginTop: -3,
                            }}
                            alt={"waiter"}
                          />
                        </span>
                        <span class="text-center" style={{ fontSize: 15 }}>
                          {language === "ENG" && "Call the waiter"}
                          {language === "Pyc" && "Позвать официанта"}
                          {language === "EST" && "Kutsuda teenindaja"}
                          {language === "LV" &&  "Pasaukt viesmīli"}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-3">
                <div class="row" style={{height: 62,  backgroundColor: "#0A2930",}}>
                  <div
                    class="col-12"
                    style={{
                      color: "white",
                      padding: 15,
                      margin: 'auto',
                      cursor: "pointer"
                    }}
                      onClick={showRightMenu}
                  >
                    <div
                      class="row"
                    >
                      <div class="col-6" style={{ color: "white" }}>
                        {language === "ENG" && "Your order"}
                        {language === "Pyc" && "Ваш заказ"}
                        {language === "EST" && "Teie tellimus"}
                        {language === "LV" &&  "Jūsu pasūtījums"}
                      </div>
                      <div class="col-6" style={{ color: "white" }}>
                        {cartValue.toFixed(2)} €{" "}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-1">
                <div class="row">
                  <div
                    class="col-12"
                    style={{
                      color: "white",
                      padding: 15,
                      height: 62
                    }}
                  >
                    <div
                      class="row"
                      style={{ cursor: "pointer" }}
                    >
                      <Languages/>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div
            style={{
              backgroundColor: "#06181C",
              height: "100vh",
              color: "white",
              display: "flex",
              justifyContent: "center",
              fontSize: 30,
            }}
            class="text-center"
          >
            <div style={{ marginTop: "40vh" }}>
              <Loader
                type="TailSpin"
                color="#ECAB04"
                height={100}
                width={100}
                timeout={3000} //3 secs
              />
            </div>
          </div>
        )}
        <HomeMenu />
        <Modal
          isOpen={callWaiter}
          onRequestClose={closeModal}
          style={customStyles}
          contentLabel="Waiter Modal"
        >
          <Waiter closeModal={closeModal} />
        </Modal>
        <Modal
          isOpen={getInvoice}
          onRequestClose={closeModalInvoice}
          style={customStylesRequest}
          contentLabel="Invoice Modal"
        >
          <RequestModal closeModalInvoice={closeModalInvoice} />
        </Modal>
        <Modal
          isOpen={paymentModal}
          onRequestClose={closePaymentModal}
          style={customStylesPayment}
          contentLabel="Payment Modal"
        >
          <Payment
            closeModalPayment={closePaymentModal}
            getInvoiceModal={getInvoiceModal}
            paymentNow={paymentNow}

            // setPendingOrder={setPendingOrder}
            // setClockOrder={setClockOrder}
          />
        </Modal>

        <Modal
          isOpen={rightMenuModal}
          onRequestClose={closeRightMenu}
          style={customStylesRightMenu}
          contentLabel="Right Menu Modal"
        >
          <RightMenu
            closeRightMenu={closeRightMenu}
            showPaymentModal={showPaymentModal}
            setPaymentNow={setPaymentNow}
          />
        </Modal>

        {/* {clockOrder && <Clock />} */}
      </div>
    </>
  );
};

export default withRouter(MainMenu);
