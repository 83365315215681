import React, { useEffect, useState } from "react";
import {
  HashRouter,
  Switch,
  Route,
} from "react-router-dom";
import "./App.css";
import Introduction from "./Components/Introduction";
import MainMenu from "./Components/MainMenu";
import SelectRestaurant from "./Components/SelectRestaurant";
import Thanks from "./Components/Thanks";
import { useHistory } from "react-router";

function App() {
  const restaurantName = localStorage.getItem('restaurantName');
  const history = useHistory();
  const [isId, setIsId] = useState(false);

  useEffect(() => {
    setIsId(true);
    localStorage.setItem("restaurantName", "2")
  }, [])

  if (!isId) {
    return (
      <div className="App">
        <SelectRestaurant setIsId={setIsId} />
      </div>
    )
  } else {
    history.push(`/`)
  }

  return (
    <div className="App">
      <Switch>
        <Route exact path="/">
          <Introduction />
        </Route>
        <Route exact path="/menu">
          <MainMenu />
        </Route>
        <Route exact path="/thanks">
          <Thanks />
        </Route>
      </Switch>
    </div>
  );
}

export default App;
