import React, { useEffect, useState } from 'react';
import { motion } from "framer-motion";
import { useDispatch, useSelector } from 'react-redux';
import Loader from "react-loader-spinner";

import closeIcon from '../Assets/close-modal.png';
import minusWhite from "../Assets/minusWhite.png";
import plusWhite from "../Assets/plusWhite.png";
import arrowOption from '../Assets/arrow-option.png';
import noImage from '../Assets/no-photo.png';

const DishModal = ({ dishDescr, setDishDescr }) => {
  const [foundation, setFoundation] = useState(null);
  const [animationClick, setAnimationClick] = useState(false);
  const language = useSelector((state) => state.translation.language);
  const [addedItemAnimation, setAddedItemAnimation] = useState(null);
  const [ordered, setOrdered] = useState([]);
  const cartItems = useSelector((state) => state.cartReducer);

  const dispatch = useDispatch();

  const handleAddFoundation = (value) => setFoundation(value);
  const handleClose = () => setDishDescr(null);

  const addItemToCart = (item) => {
    if (item.foundations && item.foundations.length > 0) {
      foundation ? item.foundation = item.foundations[foundation] : item.foundation = item.foundations[0];
    }
    setFoundation(null)
    const check = cartItems.Carts.filter((dish) => dish.id === item.id)[0];
    setAddedItemAnimation(item);
    if (!check) {
      setTimeout(() => {
        dispatch({ type: "ADD_CART", payload: item });
        setAddedItemAnimation(null);
        setAnimationClick(item);
        setTimeout(() => {
          setAnimationClick(null);
        }, 500)
      }, 300);
      return;
    } else {
      setTimeout(() => {
        dispatch({ type: "ADD_CART", payload: item });
        setAddedItemAnimation(null);
        setAnimationClick(item)
        setTimeout(() => {
          setAnimationClick(null);
        }, 500)
      }, 300);
    }
  };

  const removeItemToCart = (item) => {
    dispatch({ type: "DECREASE_QUANTITY", payload: item });
  };

  const addItemToCarts = (item) => {
    setAnimationClick(item);
    setTimeout(() => {
      setAnimationClick(null);
    }, 500)
    dispatch({ type: "ADD_CART", payload: item });
  };

  useEffect(() => {
    setOrdered(cartItems.Carts);
  }, [cartItems]);

  const isPhoto = dishDescr && dishDescr.image_url && dishDescr.image.length > 0;

  return (
    <div
      style={{
        position: 'fixed', top: 0, right: 0, bottom: 0, left: 0, background: 'rgba(0, 0, 0, 0.5)',
        display: dishDescr ? 'flex' : 'none', alignItems: 'center', justifyContent: 'center', zIndex: 10
      }}
    >
      {dishDescr &&
        <div
          style={{
            width: '997px', minHeight: '403px', background: '#06181C',
            boxSizing: 'border-box', boxShadow: '0px 0px 50px rgba(0, 0, 0, 0.2)',
            borderRadius: '5px', position: 'relative', display: 'flex',
            justifyContent: 'space-between',
            padding: '55px 35px 51px 77px', textAlign: 'left'
          }}
        >
          <img
            onClick={handleClose}
            src={closeIcon}
            alt={"name"}
            style={{ position: 'absolute', top: 15, right: 15 }}
          />
          <img
            src={isPhoto ? dishDescr.image_url : noImage}
            alt={dishDescr.name}
            style={{
              width: isPhoto ? 316 : 'auto', height: !isPhoto && '150px', alignSelf: isPhoto && 'center',
              margin: !isPhoto && '40px auto'
            }}
          />
          <div style={{ position: 'absolute' }}>
            <motion.img
              src={isPhoto ? dishDescr.image_url : noImage}
              style={{
                width: isPhoto ? 315 : 143, height: isPhoto ? 315 : 150,
                margin: !isPhoto && '60px auto 0 140px'
              }}
              animate={animationClick && animationClick.id === dishDescr.id ? {
                x: isPhoto ? 2000 : 1500,
                opacity: 0,
                y: -600,
                height: isPhoto ? -300 : 0,
                width: isPhoto ? -300 : 0,
                zIndex: 10,
              } : { display: 'none', x: 0, y: 0, zIndex: -12 }}
              transition={{
                duration: animationClick && animationClick.id === dishDescr.id ? 1 : 0,
              }}
            />
          </div>
          <div style={{ width: '467px' }}>
            <div style={{
              fontWeight: 'bold', fontSize: '25px', lineHeight: '30px',
              color: '#FFFFFF', marginBottom: '12px'
            }}
            >
              {language === "ENG" && dishDescr.name_en}
              {language === "Pyc" && (dishDescr.name_ru ? dishDescr.name_ru : dishDescr.name)}
              {language === "EST" && dishDescr.name}
              {language === "LV" && (dishDescr.name_lt ? dishDescr.name_lt : dishDescr.name)}
            </div>
            <div style={{
              fontSize: '13px', lineHeight: '19px',
              color: 'rgba(255, 255, 255, 0.7)',
              textShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
              marginBottom: '22px'
            }}
            >
              {language === "ENG" && dishDescr.description_en}
              {language === "Pyc" && (dishDescr.description_ru ? dishDescr.description_ru : dishDescr.description)}
              {language === "EST" && dishDescr.description}
              {language === "LV" && (dishDescr.description_lt ? dishDescr.description_lt : dishDescr.description)}
            </div>
            <div style={{ marginBottom: '38px', display: 'flex' }}>
              {
                dishDescr && dishDescr.weight &&
                <div
                  style={{
                    border: '1px solid rgba(255, 255, 255, 0.1)', borderRadius: '3px',
                    padding: '3px 15px', minWidth: '78px', height: '25px', display: 'flex',
                    alignItems: 'center', justifyContent: 'center'
                  }}
                >
                  {language === "ENG" && dishDescr.weight && `${dishDescr.weight} r.`}
                  {language === "Pyc" && dishDescr.weight && `${dishDescr.weight} г.`}
                  {language === "EST" && dishDescr.weight && `${dishDescr.weight} g.`}
                  {language === "LV" && dishDescr.weight && `${dishDescr.weight} g.`}
                </div>
              }
            </div>
            <div style={{ display: 'flex', alignItems: 'center', height: 28 }}>
              {
                dishDescr.foundations && dishDescr.foundations.length > 0 &&
                <div style={{ marginRight: '12px' }}>
                  <select onChange={(e) => handleAddFoundation(e.target.value)}
                    style={{
                      width: '157px', height: '40px', border: '1px solid #2D3536',
                      boxSizing: 'border-box', borderRadius: '150px', color: '#fff', outline: 'none',
                      padding: '8px 19px', background: `url(${arrowOption}) no-repeat right #06181c`, webkitAppearance: 'none', backgroundPositionX: '128px'
                    }}>
                    {
                      dishDescr.foundations.map((foundation, i) => (
                        <option value={i} key={i} style={{
                          color: "rgba(255, 255, 255, 0.7)", lineHeight: '19px',
                          fontWeight: 400, fontSize: '13px'
                        }}>
                          {language === "ENG" && (foundation.name_en ? foundation.name_en : foundation.name)}
                          {language === "Pyc" && (foundation.name_ru ? foundation.name_ru : foundation.name)}
                          {language === "EST" && foundation.name}
                          {language === "LV" && (foundation.name_lt ? foundation.name_lt : foundation.name)}
                        </option>
                      ))
                    }
                  </select>

                </div>
              }

              <div style={{ color: "#ECAB04" }}>
                <div style={{ cursor: "pointer" }}>
                  {addedItemAnimation &&
                    addedItemAnimation.id === dishDescr.id ? (
                    <Loader
                      type="TailSpin"
                      color="#ECAB04"
                      width={25}
                      height={20}
                      style={{ marginLeft: '40px' }}
                    />
                  ) : (ordered.filter(e => e.id === dishDescr.id).length > 0 &&
                    dishDescr.foundations && dishDescr.foundations.length === 0)

                    ? <span style={{ display: 'flex' }}>
                      <img
                        onClick={() => removeItemToCart(dishDescr.id)}
                        src={minusWhite}
                        alt={dishDescr.name}
                        style={{ cursor: "pointer" }}
                      />
                      <span
                        style={{
                          fontWeight: 600, fontSize: '16px',
                          lineHeight: ' 19px', display: 'flex',
                          alignItems: 'center', color: ' #FFFFFF',
                          margin: '0 11px', minWidth: '20px', justifyContent: 'center'
                        }}
                      >
                        {cartItems.Carts.filter(e => e.id === dishDescr.id)[0]
                          ? cartItems.Carts.filter(e => e.id === dishDescr.id)[0].quantity
                          : '0'}
                      </span>
                      <img
                        onClick={() => addItemToCarts(dishDescr)}
                        src={plusWhite}
                        alt={dishDescr.name}
                        style={{ cursor: "pointer" }}
                      />
                    </span>
                    : <span className="btn-cart"
                      onClick={() => addItemToCart(dishDescr)}
                      style={{
                        border: "1px solid rgba(236, 171, 4, 0.3)",
                        borderRadius: '150px',
                        padding: '0 11px',
                        minWidth: '85px',
                        height: '40px',
                        fontWeight: 'bold',
                        fontSize: '16px',
                        lineHeight: '19px',
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",

                      }}
                    >
                      <span style={{ fontWeight: '500', marginRight: 5 }}>+</span> {dishDescr.price} €
                      {
                        dishDescr.old_price &&
                        <span className="old-price">
                          {dishDescr.old_price} €
                        </span>
                      }
                    </span>
                  }
                </div>
              </div>
            </div>
            {
              dishDescr && dishDescr.alergens && dishDescr.alergens.length > 0 &&
              <div style={{ marginTop: '38px' }}>
                <div
                  style={{
                    fontWeight: 600, fontSize: '13px', lineHeight: '20px',
                    display: 'flex', alignItems: 'center', color: '#FFFFFF',
                    marginBottom: '2px'
                  }}
                >
                  {language === "ENG" && 'Allergens'}
                  {language === "Pyc" && 'Аллергены'}
                  {language === "EST" && 'Allergeenid'}
                  {language === "LV" && 'Alergēni'}
                </div>
                <div
                  style={{
                    fontSize: ' 13px', lineHeight: '19px', display: 'flex',
                    alignItems: 'center', color: 'rgba(255, 255, 255, 0.5)', flexWrap: 'wrap'
                  }}
                >
                  {
                    dishDescr.alergens && dishDescr.alergens.map((alergen, i) => {
                      return (
                        <span style={{ marginRight: 5 }}>
                          {language === "ENG" && alergen.name_en}
                          {language === "Pyc" && (alergen.name_ru ? alergen.name_ru : alergen.name)}
                          {language === "EST" && alergen.name}
                          {language === "LV" && (alergen.name_lt ? alergen.name_lt : alergen.name)}
                          {dishDescr.alergens.length - 1 !== i && ', '}
                        </span>
                      )
                    })
                  }
                </div>
              </div>
            }
          </div>
        </div>
      }
    </div>
  )
}
export default DishModal;


