import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import { HashRouter} from "react-router-dom";
// import {PersistGate} from 'redux-persist/integration/react';
import {Provider} from 'react-redux';
import {store} from './redux/store';

ReactDOM.render(
  <Provider store={store}>
    <HashRouter>
      {/* <PersistGate loading={null} persistor={persistor}> */}
        <App />
      {/* </PersistGate> */}
      </HashRouter>
    </Provider>,
  document.getElementById('root')
);






