import React, { useEffect, useState } from "react";
import OtpInput from "react-otp-input";
import { withRouter, useLocation, useHistory } from "react-router";
import logo from "../Assets/logo.png";
import { useDispatch, useSelector } from "react-redux";

const SelectRestaurant = (props) => {
  const [language, setLanguage] = useState("Pyc");
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  // const tableNumber = useSelector((state) => state.userSettingsKey);
  const selectedLanguage = useSelector((state) => state.translation.language);
   
  const changeLanguage = (lang) => {
    dispatch({ type: "CHANGE_LANGUAGE", payload: lang });
    setLanguage(lang);
  };
  let [restaurantName, setRestaurantName] = useState('');


  // alert(restaurantName)
  const [input, setInput] = useState(null);

  const navigateToMenu = () => {
    if (input && input.length > 0) {
      setRestaurantName(input);
      localStorage.setItem('restaurantName', input);
      props.setIsId(true)
    }
  };

  return (
    <div style={{ background: "linear-gradient(rgb(2, 61, 72) 0%, rgb(4, 38, 44) 18.23%)", height: "100vh" }}>
      <div class="container-fluid" style={{ height: "100%" }}>
        <div class="row justify-content-center">
          <div
            class="col-12 justify-content-center"
            style={{ marginTop: "1%" }}
          >
            <span
              onClick={() => changeLanguage("EST")}
              style={{
                fontWeight: language === "EST" ? "700" : "400",
                color:
                  language === "EST" ? "white" : "rgba(255, 255, 255, 0.7)",
                margin: 5,
                cursor: "pointer",
                backgroundColor:
                  language === "EST"
                    ? "rgba(255, 255, 255, 0.15)"
                    : "transparent",
                borderRadius: 50,
                padding: 10,
              }}
            >
              EST
            </span>
            <span
              onClick={() => changeLanguage("Pyc")}
              style={{
                fontWeight: language === "Pyc" ? "700" : "400",
                color:
                  language === "Pyc" ? "white" : "rgba(255, 255, 255, 0.7)",
                margin: 5,
                cursor: "pointer",
                backgroundColor:
                  language === "Pyc"
                    ? "rgba(255, 255, 255, 0.15)"
                    : "transparent",
                borderRadius: 50,
                padding: 10,
              }}
            >
              Pyc
            </span>
            <span
              onClick={() => changeLanguage("ENG")}
              style={{
                fontWeight: language === "ENG" ? "700" : "400",
                color:
                  language === "ENG" ? "white" : "rgba(255, 255, 255, 0.7)",
                margin: 5,
                cursor: "pointer",
                backgroundColor:
                  language === "ENG"
                    ? "rgba(255, 255, 255, 0.15)"
                    : "transparent",
                borderRadius: 50,
                padding: 10,
              }}
            >
              ENG
            </span>
          </div>

          <div class="row" style={{ marginTop: "4%", marginBottom: "4%" }}>
            <div class="col-12 justify-content-center ">
              <img src={logo} style={{ width: 121, height: 57 }} alt={"logo"} />
            </div>
          </div>

          <div class="row">
            <div
              class="col-12 text-center"
              style={{ color: "white", fontSize: 55, fontWeight: "700" }}
            >
              {language === "ENG" && "Welcome"}
              {language === "Pyc" && "Приветствуем!"}
              {language === "EST" && "Tere tulemast"}
            </div>
          </div>
          <div class="row">
            <div
              class="col-12 text-center"
              style={{
                color: "rgba(255, 255, 255, 0.7)",
                fontSize: 20,
                fontWeight: "400",
              }}
            >
              {language === "ENG" && "Enter the id of your restaurant"}
              {language === "Pyc" && "Введите id вашего ресторана"}
              {language === "EST" && "Sisestage oma restorani ID"}

            </div>
          </div>

          <div
            class="row justify-content-center"
            style={{ marginTop: "5%", marginBottom: "5%" }}
          >
            <div class="col-12 justify-content-center text-center">
              <OtpInput
                value={input}
                onChange={(e) => setInput(e)}
                numInputs={2}
                inputStyle={{
                  textAlign: "center",
                  outline: "none",
                  border: "none",
                  borderBottom: "1px solid white",
                  color: "white",
                  backgroundColor: "transparent",
                  width: 62,
                  margin: 5,
                  fontSize: 55,
                  fontWeight: "700",
                  height: 100,
                }}
                shouldAutoFocus={true}
                isInputNum={false}
                containerStyle={{ justifyContent: "center" }}
              />
            </div>
          </div>

          <div class="row" style={{ marginTop: "4%", marginBottom: "5%" }}>
            <div class="col-12 justify-content-center">
              <button
                style={{
                  border: "none",
                  padding: 15,
                  backgroundColor: "#ECAB04",
                  width: 171,
                  color: "white",
                  borderRadius: 125,
                  fontSize: 13,
                  fontWeight: "700",
                }}
                onClick={navigateToMenu}
              >
                {language === "ENG" && "Enter"}
                {language === "Pyc" && "Готово"}
                {language === "EST" && "Sisenema"}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default withRouter(SelectRestaurant);
