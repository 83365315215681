import React from "react";
import logo from "../Assets/logo.png";
import { useHistory, withRouter } from "react-router";
import cross from "../Assets/Cross.png";
import { useSelector} from "react-redux";


const Thanks = (props) => {
  const restaurantName = localStorage.getItem('restaurantName');
  const language = useSelector((state) => state.translation.language);

  const history = useHistory()
  return (
    <div style={{ backgroundColor: "#06181C", height: "100vh", display: 'flex', justifyContent: 'center'}}>
      <div class="container-fluid">
        <div class="row">
          <div
            class="d-flex"
            style={{ color: "white", justifyContent: "flex-end" }}
            onClick={() => history.push(`/`)}
          >
            <img
              src={cross}
              style={{ width: 16, height: 16, cursor: "pointer", position: 'absolute', top: 24, right: 24 }}
              alt="cross"
            />
          </div>
        </div>
        <div class="row">
          <div
            class="col-12 justify-content-center "
            style={{ marginTop: "146px", marginBottom: "142px" }}
          >
            <img src={logo} style={{ width: 121, height: 57 }} alt={"logo"} />
          </div>
        </div>

        <div class="row" style={{ marginBottom: "20px" }}>
          <div
            class="col-12 text-center"
            style={{ color: "white", fontSize: 55, fontWeight: "bold" }}
          >
            {language === "ENG" && "Thank you for order!"}
            {language === "Pyc" && "Спасибо за заказ!"}
            {language === "EST" && "Aitäh tellimuse eest!"}
            {language === "LV" && "Paldies par pasūtījumu!"}
          </div>
        </div>
        <div class="row" style={{ marginTop: "2%", marginBottom: "2%" }}>
          <div
            class="col-12 text-center"
            style={{
              color: "rgba(255, 255, 255, 0.7)",
              fontSize: 20,
              fontWeight: "400",
            }}
          >
            {language === "ENG" && "We bring your order as soon as possible. Usually it takes about 30 minutes"}
            {language === "Pyc" && "Мы принесём заказ как можно скорее. Обычно это занимает ~30 минут. "}
            {language === "EST" && "Teie tellimus jõuab Teieni nii kiiresti, kui see võimalik. Tavaline ooteag on umbes 30 minutit"}
            {language === "LV" && "Mēs nogādāsim jūsu pasūtījumu pēc iespējas ātrāk. Parasti tas aizņem apmēram 30 minūtes"}
          </div>
        </div>
      </div>
    </div>
  );
};

export default withRouter(Thanks);
