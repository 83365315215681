import React from "react";
import requestTick from "../Assets/RequestTick.png";
import { withRouter } from "react-router";
import cross from "../Assets/Cross.png";
import { useSelector} from "react-redux";

const RequestModal = (props) => {
  const language = useSelector((state) => state.translation.language);

  return (
    <div
      class="container-fluid"
      style={{ justifyContent: "center", alignItems: "center" }}
    >
      <div>
        <div
          class="d-flex"
          style={{
            color: "white",
            justifyContent: "flex-end",
            cursor: "pointer",
          }}
          // onClick={() => props.history.push("/")}
          onClick={() => props.closeModalInvoice()}
        >
          <img
            src={cross}
            style={{ width: 16, height: 16, cursor: "pointer", position: 'absolute', top: 17, right: 17 }}
            alt="cross"
          />
        </div>
      </div>
      <div
        class="row d-flex"
        style={{ justifyContent: "center", alignItems: "center" }}
      >
        <img
          src={requestTick}
          style={{
            width: "100px",
            // marginRight: 20,
            // marginTop: -3,
          }}
          alt={"requestTick"}
        />
      </div>
      <div
        class="row d-flex"
        style={{
          marginTop: 28,
          justifyContent: "center",
          color: "white",
          fontSize: 35,
          fontWeight: "700",
          fontWeight: "bold",
          lineHeight: "43px",
        }}
      >
        {language === "ENG" && "We have received your request"}
        {language === "Pyc" && "Мы получили ваш запрос"}
        {language === "EST" && "Saime teie taotluse kätte"}
      </div>
      <div
        class="row d-flex"
        style={{
          justifyContent: "center",
          color: "rgba(255, 255, 255, 0.7)",
          fontSize: 16,
          fontWeight: "normal",
          marginTop: 5,
          lineHeight: '45px'
        }}
      >
        {language === "ENG" && "Thanks! The waiter will bring you the bill soon"}
        {language === "Pyc" && "Спасибо! Официант скоро принесет вам счет"}
        {language === "EST" && "Aitäh! Kelner toob sulle varsti arve"}
      </div>
    </div>
  );
};

export default withRouter(RequestModal);
