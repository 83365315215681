import React, { useState, useEffect, useRef } from "react";
import { motion } from "framer-motion";
import { useSelector, useDispatch } from "react-redux";
import Loader from "react-loader-spinner";
import { instance, getConfig } from "../Api/init";

import minusWhite from "../Assets/minusWhite.png";
import plusWhite from "../Assets/plusWhite.png";
import arrowOption from '../Assets/arrow-option.png';

import icon from "../Assets/i.png";
import DishModal from "./DishModal";
import CheckAge from "./CheckAge";
import noImage from '../Assets/no-photo.png';

const Dashboard = (props) => {
  const [animationClick, setAnimationClick] = useState(false);

  const [iconClickElement, setIconClickElement] = useState(null);


  const dispatch = useDispatch();
  const [numItems, setNumItems] = useState(6);
  const [loaderItems, setLoaderItems] = useState(false);

  const [addedItemAnimation, setAddedItemAnimation] = useState(null);
  const [checkingAge, setCheckingAge] = useState(false);
  const [isAdult, setIsAdult] = useState(null);

  const dishesMenuData = useSelector((state) => state.dishesMenuReducer);
  const language = useSelector((state) => state.translation.language);
  // const selectedLanguage = useSelector((state) => state.translation);
  const cartItems = useSelector((state) => state.cartReducer);
  const [foundation, setFoundation] = useState(null);
  const [ordered, setOrdered] = useState([]);
  const [dishDescr, setDishDescr] = useState(null);
  const [activeCategory, setActiveCategory] = useState(null);
  const restaurantName = localStorage.getItem('restaurantName');
  const handleAddFoundation = (value) => setFoundation(value);
  const [isLoadPhoto, setIsLoadPhoto] = useState(false);
  const [filterByCategory, setFilterByCategory] = useState(null);
  const adddingNewItem = (item) => {
    console.log(item)
    if (item.foundations && item.foundations.length > 0) {
      foundation ? item.foundation = item.foundations[foundation] : item.foundation = item.foundations[0];
    }
    setFoundation(null);
    const check = cartItems.Carts.filter((dish) => dish.id === item.id)[0];
    setAddedItemAnimation(item);
    if (!check) {
      setTimeout(() => {
        dispatch({ type: "ADD_CART", payload: item });
        setAddedItemAnimation(null);
        setAnimationClick(item);
        setTimeout(() => {
          setAnimationClick(null);
        }, 500)
      }, 300);
      return;
    } else {
      setTimeout(() => {
        dispatch({ type: "ADD_CART", payload: item });
        setAddedItemAnimation(null);
        setAnimationClick(item)
        setTimeout(() => {
          setAnimationClick(null);
        }, 500)
      }, 300);
    }
  }

  const addItemToCart = (item) => {
    if (item.description_ru && item.description_ru.includes('Внимание')) {
      setCheckingAge(item);
    } else {
      adddingNewItem(item);
    }
  };

  useEffect(() => {
    if (isAdult === 'yes') {
      adddingNewItem(checkingAge);
      setCheckingAge(false);
      setIsAdult(null);
    } else if (isAdult === 'no') {
      setCheckingAge(false);
      setIsAdult(null);
    }
  }, [isAdult])

  useEffect(() => {
    setNumItems(6);
    setActiveCategory(dishesMenuData.menuId)
    setFilterByCategory(null);
  }, [dishesMenuData])

  const removeItemToCart = (item) => {
    dispatch({ type: "DECREASE_QUANTITY", payload: item });
  };

  const addItemToCarts = (item) => {
    setAnimationClick(item);
    setTimeout(() => {
      setAnimationClick(null);
    }, 500)
    dispatch({ type: "ADD_CART", payload: item });
  };

  useEffect(() => {
    setOrdered(cartItems.Carts);
  }, [cartItems]);

  const iconClick = async (dish) => {
    setIconClickElement(dish);
    // setIsIconClick(true);
  };

  const showDescr = (dish) => {
    instance.get(`product/${dish.id}`)
      .then(resp => {
        if (dishesMenuData && dishesMenuData.menuId === 'discounts') {
          setDishDescr({ ...resp.data.response.product, old_price: resp.data.response.product.price, price: resp.data.response.product.price_new })
        } else {
          setDishDescr(resp.data.response.product)
        }
      })
      .catch(setDishDescr(dish))
  }

  window.addEventListener('scroll', function (e) {
    let winScrollTop = document.querySelector('html')
    if (winScrollTop) {
      winScrollTop = winScrollTop.scrollTop
      if (document.querySelector('.fixed-categories span')) {
        if (winScrollTop > 160) {
          document.querySelector('.fixed-categories').classList.add('active')
          document.querySelector('.fixed-categories-top').classList.add('active')
        } else {
          document.querySelector('.fixed-categories').classList.remove('active')
          document.querySelector('.fixed-categories-top').classList.remove('active')
        }
      } else {
        document.querySelector('.fixed-categories').classList.remove('active')
        document.querySelector('.fixed-categories-top').classList.remove('active')
      }
    }
  })


  const getDishesData = async (id) => {
    props.setIsLoad(true)
    const response = await instance.get(`category-products?categoryId=${id}&restaurantId=${restaurantName}`, getConfig());
    // console.log(response.data.response.category.products);
    if (response) {
      setTimeout(() => {
        props.setIsLoad(false)
      }, 1000)
    }

    dispatch({
      type: "GET_DISHES",
      payload: response.data.response.category.products.sort((a, b) => a.sort_order - b.sort_order),
    });

    // setDishes(response.data.response.products);
  };

  const categoryClick = (dish) => {
    setFilterByCategory(dish.id);
    setActiveCategory(dish.id);
    console.log(dish.id);
    console.log()
  }
  const myRefs = useRef([]);
  const myRef = useRef();

  useEffect(() => {
    document.addEventListener("click", handleClickOutside, false);
    return () => {
      document.removeEventListener("click", handleClickOutside, false);
    };

  }, []);

  const handleClickOutside = (event) => {
    if (myRef.current && !myRef.current.contains(event.target)) {
      setIconClickElement(null);
    }
  };

  useEffect(() => {
    if (props.isLoad) {
      setIsLoadPhoto(false)
    } else {
      setTimeout(() => {
        setIsLoadPhoto(true)
      }, 200)
    }
  }, [props.isLoad])

  return (
    <>
      <DishModal dishDescr={dishDescr} setDishDescr={setDishDescr} />
      <CheckAge
        checkingAge={checkingAge}
        setCheckingAge={setCheckingAge}
        setIsAdult={setIsAdult}
      />
      <div
        class="container-fluid"
        style={{ marginTop: 50, minHeight: '100vh' }}
      >
        <div class="row fixed-categories-top" style={{ margin: '60px 0px 30px', fontSize: 25, display: 'flex' }} >
          <div style={{ fontSize: 60, textAlign: 'left', marginBottom: 20, lineHeight: 1.1 }}>
            {language === "ENG" && (dishesMenuData.menuName.name_en ? dishesMenuData.menuName.name_en : dishesMenuData.menuName.name)}
            {language === "Pyc" && (dishesMenuData.menuName.name_ru ? dishesMenuData.menuName.name_ru : dishesMenuData.menuName.name)}
            {language === "EST" && (dishesMenuData.menuName.name)}
            {language === "LV" && (dishesMenuData.menuName.name_lt ? dishesMenuData.menuName.name_lt : dishesMenuData.menuName.name)}
          </div>
          {
            dishesMenuData && dishesMenuData.menuId === 'discounts' &&
            <div style={{ color: 'red', fontSize: 15, textAlign: 'left' }}>
              {language === "ENG" && 'Discount is valid only when ordering take away'}
              {language === "Pyc" && 'Скидки доступны только при заказе с собой'}
              {language === "EST" && 'Soodustus kehtib ainult kaasa tellides'}
              {language === "LV" && 'Pieejams tikai pasūtot pie Jums'}
            </div>
          }
          {
            dishesMenuData && dishesMenuData.menuId === 'dishesOdTheDay' &&
            <div style={{ color: 'rgba(255, 255, 255, 0.5)', fontSize: 25, textAlign: 'left', fontWeight: 'bold' }}>
              {dishesMenuData.dishesOdTheDay.menu_time_from} - {dishesMenuData.dishesOdTheDay.menu_time_to}
            </div>
          }
          <div className="fixed-categories" style={{ display: 'flex', overflowY: 'hidden', paddingBottom: 4 }}>
            {
              dishesMenuData.categories &&
              dishesMenuData.categories.map((category, i) => {
                return <span
                  onClick={() => categoryClick(category)}
                  style={{
                    height: 33, maxWidth: 'auto', display: 'flex', alignItems: 'center', whiteSpace: "nowrap",
                    justifyContent: 'center', marginRight: 10, background: activeCategory && activeCategory === category.id ? 'rgb(236, 171, 4)' : 'rgba(255, 255, 255, 0.15)',
                    padding: '3px 16px', fontSize: 15, borderRadius: 3, cursor: 'pointer'
                  }}
                >
                  {language === "ENG" && (category.name_en ? category.name_en : category.name)}
                  {language === "Pyc" && (category.name_ru ? category.name_ru : category.name)}
                  {language === "EST" && (category.name)}
                  {language === "LV" && (category.name_lt ? category.name_lt : category.name)}
                </span>
              })
            }
          </div>
        </div>

        <div class="row" ref={myRef} style={{ marginBottom: 20, }}>
          {dishesMenuData.dishes
            .filter(dish => filterByCategory ? dish.categories[0].id === filterByCategory : true)
            .slice(0, numItems)
            .map((dish, key) => {
              const isPhoto = dish.image_url && dish.image_url;
              return (
                <div
                  key={key}
                  class="col-5"
                  style={{ background: 'rgba(2, 17, 20, 0.5)', borderRadius: '15px', margin: '0 24px 24px 0', padding: '0 28px 22px', width: '429px', minHeight: '465px', position: 'relative', display: 'flex', flexDirection: "column", justifyContent: "space-between" }}
                >
                  <div class="row" style={{ marginTop: 10, marginBottom: 10 }}>
                    <div class="col-10"></div>
                  </div>
                  <div style={{
                    position: 'absolute', top: 17, left: 17, padding: '4px 8px',
                    border: '1px  solid rgba(255, 255, 255, 0.1)', borderRadius: '4px',
                    color: ' rgba(255, 255, 255, 0.7)', fontSize: 13, lineHeight: '19px'
                  }}
                  >
                    {dish.number}
                  </div>
                  <div onClick={() => showDescr(dish)} class="row" style={{ justifyContent: "center", alignItems: 'center', height: 315 }} onClick={() => iconClickElement && iconClickElement.id === dish.id ? iconClick(null) : iconClick(dish)}>
                    <img
                      onClick={() => showDescr(dish)}
                      src={isPhoto ? dish.image_url : noImage}
                      alt={dish.name}
                      style={{
                        width: isPhoto ? 315 : 'auto', height: isPhoto ? 315 : 150, display: props.isLoad ? 'none' : 'block',
                        opacity: isLoadPhoto ? 1 : 0,
                        transition: 'all 1s'
                      }}
                    />
                    <div style={{ position: 'absolute' }}>
                      <motion.img
                        src={isPhoto ? dish.image_url : noImage}
                        style={{ width: isPhoto ? 315 : 140, height: isPhoto ? 315 : 150, zIndex: -21 }}
                        animate={animationClick && animationClick.id === dish.id ? {
                          x: key % 2 === 0 ? 1000 : 200,
                          opacity: 0,
                          y: isPhoto ? -600 : -550,
                          height: isPhoto ? -300 : 0,
                          width: isPhoto ? -300 : 0,
                          zIndex: 10
                        } : { display: 'none', x: 0, y: 0, zIndex: -12 }}
                        transition={{
                          duration: animationClick && animationClick.id === dish.id ? 1 : 0,
                        }}
                      />
                    </div>
                  </div>
                  <div
                    onClick={() => showDescr(dish)}
                    class="row"
                    style={{
                      margin: '6px 0 6px',
                      fontSize: '18px',
                      fontWeight: "400",
                      color: "white",
                      lineHeight: '24px',
                      textAlign: 'left',
                    }}
                  >
                    {language === "ENG" && (
                      <span style={{ textAlign: 'center', padding: 0, fontSize: 18 }}>{dish.name_en}</span>
                    )}
                    {language === "Pyc" && (
                      <span style={{ textAlign: 'center', padding: 0, fontSize: 18 }}>{dish.name_ru ? dish.name_ru : dish.name}</span>
                    )}
                    {language === "EST" && (
                      <span style={{ textAlign: 'center', padding: 0, fontSize: 18 }}>{dish.name}</span>
                    )}
                    {language === "LV" && (
                      <span style={{ textAlign: 'center', padding: 0, fontSize: 18 }}>{dish.name_lt ? dish.name_lt : dish.name}</span>
                    )}
                  </div>
                  {/* <div class="row" style={{ height: 25 }}></div> */}

                  <div class="row" style={{ marginTop: 30, height: 40, justifyContent: 'space-between', position: 'relative', alignItems: 'center' }}>
                    <div class="col-2" style={{ fontSize: '16px', lineHeight: '19px', color: 'rgba(255, 255, 255, 0.7)', padding: 0 }}>
                      {language === "ENG" && dish.weight && `${dish.weight} g.`}
                      {language === "Pyc" && dish.weight && `${dish.weight} г.`}
                      {language === "EST" && dish.weight && `${dish.weight} g.`}
                      {language === "LV" && dish.weight && `${dish.weight} g.`}
                    </div>
                    {
                      dish.foundations && dish.foundations.length > 0 &&
                      <div class="col-6" style={{ position: 'relative' }}>
                        <select onChange={(e) => handleAddFoundation(e.target.value)}
                          style={{
                            width: '157px', height: '40px', border: '1px solid #2D3536',
                            boxSizing: 'border-box', borderRadius: '150px', color: '#fff', outline: 'none',
                            padding: '8px 19px', background: `url(${arrowOption}) no-repeat right #06181c`, webkitAppearance: 'none', backgroundPositionX: '128px'
                          }}>
                          {
                            dish.foundations.map((foundation, i) => (
                              <option value={i} key={i} style={{
                                color: "rgba(255, 255, 255, 0.7)", lineHeight: '19px',
                                fontWeight: 400, fontSize: '13px'
                              }}>
                                {language === "ENG" && (foundation.name_en ? foundation.name_en : foundation.name)}
                                {language === "Pyc" && (foundation.name_ru ? foundation.name_ru : foundation.name)}
                                {language === "EST" && foundation.name}
                                {language === "LV" && (foundation.name_lt ? foundation.name_lt : foundation.name)}
                              </option>
                            ))
                          }
                        </select>
                        <div>
                        </div>
                      </div>
                    }
                    {/* <div
                      class="col-3"
                      style={{ color: "#ECAB04", fontWeight: "bold", fontSize: 18 }}
                    >
                      {dish.price} €
                    </div> */}

                    <div class="col-4" style={{ color: "#ECAB04" }}>
                      <div class="row">
                        <div class="col-12" style={{ cursor: "pointer" }}>
                          {addedItemAnimation &&
                            addedItemAnimation.id === dish.id ? (
                            <Loader
                              type="TailSpin"
                              color="#ECAB04"
                              width={25}
                              height={20}
                            />
                          ) : (ordered.filter(e => e.id === dish.id).length > 0 &&
                            dish.foundations && dish.foundations.length === 0)
                            ? <span style={{ display: 'flex' }}>
                              <img
                                onClick={() => removeItemToCart(dish.id)}
                                src={minusWhite}
                                alt={dish.name}
                                style={{ cursor: "pointer" }}
                              />
                              <span
                                style={{
                                  fontWeight: 600, fontSize: '16px',
                                  lineHeight: ' 19px', display: 'flex',
                                  alignItems: 'center', color: ' #FFFFFF',
                                  margin: '0 11px', minWidth: '20px', justifyContent: 'center'
                                }}
                              >
                                {cartItems.Carts.filter(e => e.id === dish.id)[0]
                                  ? cartItems.Carts.filter(e => e.id === dish.id)[0].quantity
                                  : '0'}
                              </span>
                              <img
                                onClick={() => addItemToCarts(dish)}
                                src={plusWhite}
                                alt={dish.name}
                                style={{ cursor: "pointer" }}
                              />
                            </span>
                            : <span className="btn-cart"
                              onClick={() => addItemToCart(dish)}
                              style={{
                                border: "1px solid rgba(236, 171, 4, 0.3)",
                                borderRadius: '150px',
                                padding: '0 11px',
                                minWidth: '85px',
                                height: '40px',
                                fontWeight: 'bold',
                                fontSize: '16px',
                                lineHeight: '19px',
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",

                              }}
                            >
                              <span style={{ fontWeight: '500', marginRight: 5 }}>+</span> {dish.price} €
                              {
                                dish.old_price &&
                                <span className="old-price">
                                  {dish.old_price} €
                                </span>
                              }
                            </span>
                          }
                        </div>
                      </div>
                    </div>
                    {/* <div class="col-1"></div> */}
                  </div>
                  {/* <div class="row" style={{ height: 25 }}></div> */}
                </div>
              );
            })}
        </div>
        <div
          style={{ width: '100%', margin: '0 auto 30px' }}
          onClick={() => iconClick(null)}
        >
          {
            // dishesMenuData.dishes &&
            // props.isLoad &&
            numItems < dishesMenuData.dishes.length &&
            (loaderItems ? (
              <Loader
                type="TailSpin"
                color="#ECAB04"
                height={100}
                width={100}
                timeout={1000} //3 secs
              />
            ) : (
              <button className="load"
                style={{
                  backgroundColor: "#ecab04",
                  padding: 15,
                  color: "white",
                  border: "none",
                  borderRadius: 10,
                }}
                onClick={() => {
                  setNumItems(numItems + 10);
                }}
              >
                {language === "ENG" && "Show More"}
                {language === "Pyc" && "Показать больше"}
                {language === "EST" && "Näita rohkem"}
                {language === "LV" && "Rodyti daugiau"}
              </button>
            ))
          }
        </div>
        {/* <div>
            {
              // dishesMenuData.dishes &&
              // props.isLoad &&
              numItems <= dishesMenuData.dishes.length && (
                <button
                  style={{
                    backgroundColor: "#ecab04",
                    padding: 10,
                    color: "white",
                    border: "none",
                    borderRadius: 10,
                  }}
                  onClick={() => setNumItems(numItems + 10)}
                >
                  Show More
                </button>
              )
            }
          </div> */}
      </div>
    </>
  );
};

export default Dashboard;
