import React from 'react';
import { useSelector} from "react-redux";
import cross from "../Assets/Cross.png";

const PaymentCheck = ({paymentCheck,setPaymentCheck, setPaymentOption}) => {
    const language = useSelector((state) => state.translation.language);

    const hadlePaymentNow = () => setPaymentOption('now');
    const hadlePaymentLater = () => setPaymentOption('later');
    const closeModal = () => setPaymentCheck(false);

    return (
        <div style={{position: 'fixed', top: 0, right: 0, bottom: 0, left: 0,
         background: 'rgba(0, 0, 0, 0.5)', display: paymentCheck ? 'flex' : 'none', 
         justifyContent: 'center', alignItems: 'center', zIndex: 1 }}>
               <div style={{width: '80%', height: 160, display: 'flex', flexDirection: 'column', zIndex: 10000,
                justifyContent:"center", alignItems: 'center', background:' #06181C',
                boxShadow: '0px 0px 50px rgba(0, 0, 0, 0.2)', borderRadius:' 5px', position: 'relative', textAlign: 'center'}}>
                        <img onClick={closeModal} src={cross} style={{position: 'absolute', top: 15, right: 15}} />
                        <div style={{fontSize: '18px', fontWeight: 'bold', color: '#fff', marginBottom: '20px'}}>
                            {language === "ENG" && "Pay your bill now?"}
                            {language === "Pyc" && "Оплатить счёт сразу? "}
                            {language === "EST" && "Maksate arve kohe?"}
                            {language === "LV" && "Vai vēlaties apmaksāt rēķinu tūlīt?"}
                        </div>
                        <div>
                            <button onClick={hadlePaymentNow} style={{borderRadius: '5px 0 0 5px', background: '#ECAB04', border: 'none',
                            width: '90px', height: '40px', fontWeight: 'bold', color: '#fff'}}>
                                {language === "ENG" && "Yes"}
                                {language === "Pyc" && "Да"}
                                {language === "EST" && "Jah"}
                                {language === "LV" && "Taip"}
                            </button>
                            <button onClick={hadlePaymentLater} style={{borderRadius: '0 5px 5px 0', background: 'rgba(255, 255, 255, 0.15)', border: 'none',
                            width: '90px', height: '40px', fontWeight: 'bold', color: '#fff'}}>
                                {language === "ENG" && "No"}
                                {language === "Pyc" && "Нет"}
                                {language === "EST" && "Ei"}
                                {language === "LV" && "Nr"}
                            </button>
                        </div>

               </div>
        </div>
    )
}

export default PaymentCheck;