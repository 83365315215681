/* eslint-disable array-callback-return */

const initProduct = {
  numberCart: 0,
  Carts: [],
  _products: [],
};

export const cartReducer = (state = initProduct, action) => {
  switch (action.type) {
    case "GET_ALL_PRODUCT":
      return {
        ...state,
        _products: action.payload,
      };
    case "GET_NUMBER_CART":
      return {
        ...state,
      };
    case "ADD_CART":
      if (state.numberCart === 0) {
        let cart = {
          quantity: 1,
          ...action.payload,
        };
        state.Carts.push(cart);
      } else {
        let check = false;
        state.Carts.map((item, key) => {
          if(item.foundation) {
            if (item.id === action.payload.id && item.foundation.id === action.payload.foundation.id) {
              state.Carts[key].quantity++;
              check = true;
            }
          } else if (item.id === action.payload.id) {
            state.Carts[key].quantity++;
            check = true;
          }
        });
        if (!check) {
          let _cart = {
            quantity: 1,
            ...action.payload,
          };
          state.Carts.push(_cart);
        }
      }
      return {
        ...state,
        numberCart: state.numberCart + 1,
      };
    case "INCREASE_QUANTITY":
      state.numberCart++;
      state.Carts[action.payload].quantity++;
      return {
        ...state,
      };
    case "DECREASE_QUANTITY":
      let quantity = state.Carts.filter(
        (item) => item.id === action.payload
      ).map((item) => {
        if (item.id === action.payload) {
          return item.quantity;
        }
      })[0];


      if (!quantity) {
        return { ...state };
      }
      if (quantity > 1) {
        let cart = state.Carts.map((item) => {
          if (item.id === action.payload) {
            let qty = item.quantity - 1;
            return { ...item, quantity: qty };
          }
          return item;
        });

        return {
          ...state,
          numberCart: state.numberCart - 1,
          Carts: cart,
        };
      }
      if (quantity === 1) {
        return {
          ...state,
          numberCart: state.numberCart - quantity,
          Carts: state.Carts.filter((item) => {
            return item.id !== action.payload;
          }),
        };
      }

      if (quantity < 1) {
        return state;
      }

      return state;

    case "DELETE_CART":
      let quantity_ = state.Carts.filter(
        (item) => item.id === action.payload
      ).map((item) => {
        if (item.id === action.payload) {
          return item.quantity;
        }
      })[0];

      return {
        ...state,
        numberCart: state.numberCart - quantity_,
        Carts: state.Carts.filter((item) => item.id !== action.payload),
      };
    case "CLEAN_CARDS":
      return {
        numberCart: 0,
        Carts: [],
        _products: []
      }
    default:
      return state;
  }
};
