import React, { useState } from "react";
import { useHistory, withRouter } from "react-router";
import cross from "../Assets/Cross.png";
import NumberFormat from 'react-number-format';
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { admitInstance } from "../Api/init";


const Payment = (props) => {
  const [isCash, setIsCash] = useState(false);
  const [waiterTip, setWaiterTip] = useState(0);
  const [rating, setRating] = useState({});
  const [comment, setComment] = useState("");
  const [promoCode, setPromoCode] = useState("");
  const dispatch = useDispatch();
  const history = useHistory();
  const language = useSelector((state) => state.translation.language);


  const table_number = localStorage.getItem('table_number');
  const restaurantName = localStorage.getItem('restaurantName');
  const cartItems = useSelector((state) => state.cartReducer);

  const total = cartItems.Carts.reduce(
    (sum, i) => (sum += i.quantity * i.price),
    0
  );
  const products = []
  cartItems.Carts.forEach((order, i) => {
    products.push({
      id: order.id,
      name: order.name,
      quantity: order.quantity,
      number: order.number,
      price: order.price,
      foundation_number: order.foundation ? order.foundation.number : null,
      foundation_name: order.foundation ? order.foundation.name : null,
    })
  })

  const ratings = [
    { id: 1, value: 1 },
    { id: 2, value: 2 },
    { id: 3, value: 3 },
    { id: 4, value: 4 },
    { id: 5, value: 5 },
  ];

  const handleSend = () => {

    if (props.paymentNow) {
      admitInstance.post('checkout/store', {
        table_number,
        restaurant_id: restaurantName,
        products,
        total,
        payment_type: isCash ? 'cash' : 'card',
        comment
      }).then((resp) => {
        localStorage.setItem('order_id', resp.data.order_id)
        history.push(`thanks`);
        dispatch({ type: "CLEAN_CARDS", payload: null });
      })
    }
    console.log({
      // isCash,
      // waiterTip,
      // rating,
      comment,
      // promoCode,
      table_number,
      restaurant_id: restaurantName,
      products,
      total,
      payment_type: isCash ? 1 : 2
    })
  }

  return (
    <div
      class="container-fluid"
      style={{ justifyContent: "center", alignItems: "center", color: "white" }}
    >
      <div
        class="d-flex"
        style={{ color: "white", justifyContent: "flex-end" }}
      >
        <img
          src={cross}
          style={{ width: 16, height: 16, cursor: "pointer", position: "absolute", top: 17, right: 17 }}
          onClick={() => props.closeModalPayment()}
          alt="cross"

        />
      </div>
      <div
        class="row"
        style={{
          margin: "0 0 28px",
          // marginTop: -10,
          fontSize: "35px",
          lineHeight: '43px',
          fontWeight: "700",
        }}
      >
        {language === "ENG" && "Method of payment"}
        {language === "Pyc" && "Какой способ оплаты?"}
        {language === "EST" && "Makseviis"}
        {language === "LV" && "Apmaksas veids"}
      </div>
      <div class="row" style={{ justifyContent: 'center', marginBottom: '38px' }}>
        <button
          style={{
            width: "287px",
            display: 'inline-block',
            borderRadius: "5px 0px 0px 5px",
            color: "white",
            border: 'none',
            backgroundColor: isCash ? "rgba(255, 255, 255, 0.1)" : "#ECAB04",
            height: "58px",
            fontSize: '16px',
            fontWeight: 'bold'
          }}
          onClick={() => setIsCash(false)}
        >
          {language === "ENG" && "Card"}
          {language === "Pyc" && "Картой"}
          {language === "EST" && "Kaardimakse"}
          {language === "LV" && "Maksāt ar karti"}
        </button>
        <button
          style={{
            width: "296px",
            height: "58px",
            borderRadius: "0px 5px 5px 0px",
            color: "white",
            border: 'none',
            backgroundColor: isCash ? "#BAC10A" : "rgba(255, 255, 255, 0.1)",
            padding: 10,
            fontSize: '16px',
            fontWeight: 'bold'
          }}
          onClick={() => setIsCash(true)}
        >
          {language === "ENG" && "Cash"}
          {language === "Pyc" && "Наличными"}
          {language === "EST" && "Sularahamakse"}
          {language === "LV" && "Skaidras naudas maksājums"}
        </button>
      </div>

      {
        !props.paymentNow &&
        <>
          {
            !isCash &&
            <>
              <div
                class="row"
                style={{
                  fontWeight: "600",
                  fontSize: "16px",
                  fontHeight: '20px',
                  marginBottom: '30px'
                }}
              >
                {language === "ENG" && "Add a Tip to the waiter?"}
                {language === "Pyc" && "Добавить чаевые официанту?"}
                {language === "EST" && "Kas lisada kelnerile jootraha?"}
                {language === "LV" && "Pridėti arbatpinigių padavėjui?"}
              </div>
              <div class="row" style={{ margin: 10, marginBottom: 37 }}>
                <span>
                  <input
                    type="text"
                    value={waiterTip}
                    onChange={(e) => setWaiterTip(e.target.value)}
                    style={{
                      backgroundColor: "transparent",
                      border: "none",
                      fontSize: '16px',
                      outline: "none",
                      borderBottom: "1px  solid rgba(255, 255, 255, 0.1)",
                      color: "rgba(236, 171, 4, 0.4)",
                      width: "30%",
                      fontWeight: "bold",
                      padding: 10,
                    }}
                  />
                  <span style={{ color: "#ECAB04", marginLeft: -20, fontSize: '16px', }}>€</span>
                </span>
              </div>
            </>
          }
          <div class="row" style={{ fontWeight: "600", marginBottom: 27 }}>
            {language === "ENG" && "Rate the dishes"}
            {language === "Pyc" && "Оцените блюда"}
            {language === "EST" && "Hinnake roogasid"}
            {language === "LV" && "Įvertinkite patiekalus"}
          </div>
          <div class="row" style={{ marginBottom: 45, }}>
            {ratings.map((item) => {
              return (
                <div
                  class="col-2 text-center"
                  style={{
                    border: "1px solid rgba(255, 255, 255, 0.1)",
                    borderRadius: 100,
                    alignItems: "center",
                    width: 60,
                    height: 60,
                    padding: 20,
                    cursor: "pointer",
                    marginRight: 10,
                    backgroundColor:
                      rating.value === item.value
                        ? isCash
                          ? "#BAC10A"
                          : "#ECAB04"
                        : "transparent",
                  }}
                  onClick={() => setRating(item)}
                  key={item.id}
                >
                  {item.value}
                </div>
              );
            })}
          </div>
        </>
      }
      <div class="row" style={{ margin: 10, marginBottom: 25 }}>
        <input
          type="text"
          value={comment}
          onChange={(e) => setComment(e.target.value)}
          placeholder={
            language === "ENG" ? "Comment"
              : language === "Pyc" ? "Комментарий"
                : language === "EST" ? "Kommentaar"
                  : language === "LV" ? "Komentēt" : ''
          }
          style={{
            backgroundColor: "transparent",
            border: "none",
            outline: "none",
            borderBottom: "1px solid rgba(255, 255, 255, 0.1)",
            color: "white",
            width: "80%",
            padding: '16px 16px 16px 0',
            fontSize: '16px'
          }}
        />
      </div>
      {/* {
        !isCash && 
          <div class="row" style={{ margin: "30px 0 33px 0", alignItems: "center"}}>
          <div
            class="col-4"
            style={{ color: "rgba(255, 255, 255, 0.4)", marginRight: 26, width: 'auto',
            fontSize: 16, fontWeight: "normal", padding: 0 }}
          >
            {language === "ENG" && "Promo code"}
            {language === "Pyc" && "Промо код"}
            {language === "EST" && "Promo kood"}
            {language === "LV" && "Reklamos kodas"}
          </div>
          <div class="col-8">
            <NumberFormat
              class="text-center"
              type="text"
              format="###-###-###"
              // mask="-"
              value={promoCode}
              onChange={(e) => setPromoCode(e.target.value)}
              placeholder="xxx-xxx-xxx"
              style={{
                backgroundColor: "rgba(255, 255, 255, 0.1)",
                outline: "none",
                border: "none",
                color: "white",
                width: "60%",
                height: "58px",
                padding: 8,
                fontSize: "16px",
              }}
            />
          </div>
        </div>
      } */}

      <div class="row">
        <button
          style={{
            border: "none",
            color: "white",
            backgroundColor: isCash ? "#BAC10A" : "#ECAB04",
            padding: 15,
            width: "780px",
          }}
          onClick={handleSend}
        >
          {language === "ENG" && "Ask for an invoice"}
          {language === "Pyc" && "Попросить счет"}
          {language === "EST" && "Küsi arvet"}
          {language === "LV" && "Paprašykite sąskaitos faktūros"}
        </button>
      </div>
    </div>
  );
};

export default withRouter(Payment);
