import React, { useEffect, useState } from 'react';
import arrowMore from '../Assets/arrow.png';
import russian from '../Assets/russian.png';
import english from '../Assets/english.png';
import estonian from '../Assets/estonian.png';
import latvia from '../Assets/latvia.png';
import { useDispatch, useSelector } from "react-redux";

const Languages = () => {
    const [changeLanguage, setChangeLanguage] = useState(false);
    const language = useSelector((state) => state.translation.language);
    const dispatch = useDispatch();
    const currentRestaurant = useSelector((state) => state.translation.currentRestaurant);

    const setLenguage = (lang) => {
        dispatch({ type: "CHANGE_LANGUAGE", payload: lang });
    };
    const handleChange = () => setChangeLanguage(!changeLanguage);

    return (
        <div onClick={handleChange} style={{display: 'flex', alignItems: 'center', position: 'relative',
        flexDirection: 'column'}}>
            <div style={{display: 'flex', alignItems: 'center', marginRight: '25px'}}>
                <div 
                    style={{width: 24, height: 24, background: `url(${language === 'EST' ? estonian : language === 'Pyc' ? russian : language === 'ENG' ? english : language === 'LV' ? latvia : russian }) center center/cover`, borderRadius: '100%',
                            marginRight: '15px'}}
                ></div>
                <img 
                    src={arrowMore} 
                    alt="" 
                    style={{transform: changeLanguage ? 'rotate(-90deg)' : 'rotate(90deg)', height: 10}}
                />
            </div>
            {
                changeLanguage &&
                <div style={{postion: 'absolute', paddingTop: 20}}>
                    {
                        currentRestaurant && currentRestaurant.location.includes('Estonia') && 
                        <div onClick={() => setLenguage('EST')} style={{display: 'flex', alignItems: 'center', marginBottom: 20}}>
                        <div 
                            style={{width: 24, height: 24, background: `url(${estonian}) center center/cover`, borderRadius: '100%',
                                    marginRight: '9px'}}
                        ></div>
                        <div>EST</div>
                         </div> 
                    }
                    <div onClick={() => setLenguage('Pyc')} style={{display: 'flex', alignItems: 'center', marginBottom: 20}}>
                        <div 
                            style={{width: 24, height: 24, background: `url(${russian}) center center/cover`, borderRadius: '100%',
                                    marginRight: '9px'}}
                        ></div>
                        <div>RUS</div>
                    </div> 
                    <div onClick={() => setLenguage('ENG')}  style={{display: 'flex', alignItems: 'center', marginBottom: 20}}>
                        <div 
                            style={{width: 24, height: 24, background: `url(${english}) center center/cover`, borderRadius: '100%',
                                    marginRight: '9px'}}
                        ></div>
                        <div>ENG</div>
                    </div> 
                   {
                       currentRestaurant && !currentRestaurant.location.includes('Estonia') && 
                        <div onClick={() => setLenguage('LV')}  style={{display: 'flex', alignItems: 'center'}}>
                            <div 
                            style={{width: 24, height: 24, background: `url(${latvia}) center center/cover`, borderRadius: '100%',
                                    marginRight: '9px'}}
                            ></div>
                            <div>LV</div>
                        </div> 
                   }
                </div>
            }
        </div>
    )
}
export default Languages;