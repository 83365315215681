import { combineReducers } from "redux";
import userSettingsReducer from "./userSettingsReducer";
import translationReducer from "./translationReducer";
import { cartReducer } from "./CartReducer";
import DishesMenuReducer from "./DishesMenuReducer";
import OrderReducer from "./OrderReducer";
export default combineReducers({
  cartReducer: cartReducer,
  userSettingsKey: userSettingsReducer,
  translation: translationReducer,
  dishesMenuReducer: DishesMenuReducer,
  OrderReducer: OrderReducer,
});
